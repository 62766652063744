import API from "./index";

export default {
  page: (url, data) => {
    return API.post(
      {
        data,url:url+'/page', auth: true 
      },
      false
    )
  },
  add: (url, data) => {
    return API.post(
      {
        data,url:url+'/add', auth: true 
      },
      false
    )
  },
  edit: (url, data) => {
    return API.post(
      {
        data,url:url+'/edit', auth: true 
      },
      false
    )
  },
  del: (url, id) => {
    return API.post(
      {
        data:{id},url:url+'/del', auth: true 
      },
      false
    )
  }
}
